.home__panel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.link__home {
    border: 1px solid var(--primary-color-light);
    margin: 8px 0px;
    cursor: pointer;
}
.link__home div {
    display: block;
    padding: 1em 1.5em;
    text-align: center;
    letter-spacing: 0.1em;
    color: var(--primary-color);
    font-weight: bold;
    text-decoration: none;
    transition: all 0.2s ease;
}

.link__home:hover {
    background-color: var(--primary-color);
    color: #fff;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.link__home div:hover {
    color: #fff;
}

@media (min-width: 768px) {
    .home__panel {
        max-width: 755px;
    }
}
