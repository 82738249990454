.header,
.header__light {
    position: fixed;
    display: flex;
    height: 4rem;
    width: 100vw;
    justify-content: space-between;
    align-items: center;
    background-color: #782b36;
    color: white;
    top: 0;
    left: 0;
    z-index: 9;
    padding: 0 1rem;
    transition: 0.3s;
}

.header__light {
    background-color: #fff;
    -webkit-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
    position: fixed;
}

.header__logo {
    flex: 3;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo__img {
    width: 8rem;
}

.header__logo {
    color: white;
    text-decoration: none;
}

.header__nav__mobile {
    display: flex;
    justify-content: center;
    margin: 0px 25px;
}

.header__auth_actions {
    margin-right: 25px;
    display: flex;
    flex-direction: row;
}
.header__auth_actions > span {
    padding: 8px;
}

.header__auth_actions .logount_button {
    font-size: 1.4em;
    cursor: pointer;
}

.header__auth_actions .logount_button:hover {
    background-color: var(--primary-color-light);
}

.wrapper-menu {
    width: 1.75em;
    height: 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    transition: transform 330ms ease-out;
}

.wrapper-menu.open {
    transform: rotate(-45deg);
}

.line-menu {
    background-color: var(--primary-color-very-light);
    border-radius: 5px;
    width: 100%;
    height: 0.25em;
}

.header__light .line-menu {
    background-color: var(--primary-color);
}

.line-menu.half {
    width: 50%;
}

.line-menu.start {
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: right;
}

.open .line-menu.start {
    transform: rotate(-90deg) translateX(3px);
}

.line-menu.end {
    align-self: flex-end;
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: left;
}

.open .line-menu.end {
    transform: rotate(-90deg) translateX(-3px);
}

.navigation-open {
    display: flex;
    flex-direction: column;
}
.navigation-open .nav__item__label {
    display: inline;
    margin-left: 16px;
    text-align: left;
}

.nav__navigation {
    width: 100%;
}

.nav__navigation .nav__item {
    align-self: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.nav__item {
    border: 1px solid var(--primary-color-light);
    margin: 8px 0px;
    cursor: pointer;
}

.nav__item:hover {
    background-color: var(--primary-color);
    color: #fff;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.nav__item a i {
    display: none;
}

.nav__item a {
    display: block;
    padding: 1em 1.5em;
    text-align: center;
    letter-spacing: 0.1em;
    font-weight: bolder;
    color: var(--grey);

    text-decoration: none;
    /* transition: background-color 0.1s ease-in-out; */
    /* transform: translateY(-20px); */
    transition: all 0.2s ease;
}
.nav__item a.nav__item__active {
    opacity: 1;
    transform: translateY(0);
}

.nav__item .active {
    color: var(--primary-color);
    font-weight: bold;
    /* border-bottom: 3px solid red; */
}

.nav__item:hover .active {
    color: #fff;
}

.nav__item .active .fas {
    font-weight: bolder;
}

@media (max-width: 767px) {
    .nav__navigation {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 70%;
        min-width: 300px;
    }
}

@media (min-width: 768px) {
    .header__light .line-menu {
        background-color: var(--primary-color-very-light);
    }

    .nav__navigation {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: #fff;
        top: 0em;
        transition: all 0.3s ease-out;
        list-style: none;
        font-weight: lighter;
        margin: 0px;
    }

    .nav__navigation .nav__item {
        align-self: center;
        border: none;
        border-bottom: 1px solid var(--primary-color-light);
    }

    .nav__navigation.navigation-open {
        width: auto;
        visibility: visible;
        transition: all 0.3s ease-in;
        flex-grow: 1;
    }
    .nav__navigation.navigation-open .nav__item {
        justify-content: start;
    }

    .nav__item {
        margin: 0px 0px;
    }

    .nav__item a i {
        display: inline-block;
    }

    .nav__navigation .nav__item__label {
        display: none;
    }

    .header__light {
        background-color: #782b36;
    }

    .nav__navigation.navigation-open {
        display: flex;
        flex-direction: column;
    }

    .navigation-open .nav__item__label {
        display: inline;
        margin-left: 16px;
    }

    .navigation-open .nav__item {
        width: 100%;
    }

    .header__logo {
        flex: 1;
        justify-content: flex-start;
    }
    .nav__item {
        transform: none;
        display: inline-block;
        /* margin-left: 20px; */
    }
    .nav__item a {
        opacity: 1;
        padding: 1em 0.5em;
        letter-spacing: normal;
        transform: unset;
        display: flex;
        justify-content: flex-start;
        /* display: flex; */
        /* justify-content: space-around; */
    }
    .nav__item--blue {
        background: var(--turquoise);
        padding: 0.5rem 3rem !important;
        color: white !important;
        border-radius: 0.5em;
    }

    .subheader h1 {
        font-size: 4em;
    }
}
