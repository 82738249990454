.questions_table {
    padding: 8x;
    /* background-color: #eee; */
}

.questions_table h2 {
    margin-bottom: 15px;
    font-weight: bold;
}

.block_table {
    display: flex;
    flex-direction: column;
    background-color: #eee;
    margin: 0 0 3em 0;
    padding: 8px;
}

.block_table .block_table__row {
    display: flex;
    /* border-top: 1px solid black;
    border-right: 1px solid black; */
}

.block_table .block_table__row span {
    flex: 1 1 0;
    min-width: 10%;
    /* word-wrap: break-word;
    overflow-wrap: break-word; */
    padding: 6px;
    /* border-left: 1px solid black;
    border-bottom: 1px solid black; */

    border: 1px solid;
    color: var(--primary-color);

    text-align: center;
    line-height: 30px;
    display: flex;
    hyphens: manual;
    word-break: break-word;
    overflow-x: hidden;

    justify-content: center;
    align-items: center;
}
.block_table .block_table__row span > div {
    min-width: 0px;
}
.block_table .block_table__body span {
    background-color: #fff;
}

.block_table .block_table__row span.item_title,
.block_table .block_table__row span.title {
    flex: 2 1 0;
}

.item__title--row {
    flex: 2 1 0;
    flex-direction: row;
    display: flex;
    width: 100%;
}

.item__title--row label {
    flex: 1 1 0;
    min-width: 10%;
    padding: 6px;
    color: var(--primary-color);

    text-align: center;
    line-height: 30px;
    display: flex;
    hyphens: manual;
    word-break: break-word;
    overflow-x: hidden;

    justify-content: center;
    align-items: center;
}

.item__description--row {
    border-top: double;
    width: 100%;
    padding: -6px;
    background: var(--blue-extra-light);
}

.block_table__header {
    font-weight: bold;
}
.block_table__footer {
    padding-top: 25px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.block_score_row {
    font-weight: bold;

    background-color: #eee;
    display: flex;
    flex-direction: row;
}

.block_inspection_result {
    text-align: center;
}

.block__answer--successful,
.block__answer--unsuccessful {
    padding: 16px;
    color: #fff;
    text-shadow: 2px 2px #555;
}

.block__answer--successful {
    background-color: #4caf50;
}

.block__answer--unsuccessful {
    background-color: #dc3545;
}

.tooltip__info {
    cursor: pointer;
    padding: 12px;
}

.block_table .block_table__row label {
    flex: 1 1 0;
    min-width: 10%;
    padding: 6px;
    /* border: 1px solid; */
    color: var(--primary-color);

    text-align: center;
    line-height: 30px;
    display: flex;
    hyphens: manual;
    word-break: break-word;
    overflow-x: hidden;

    justify-content: center;
    align-items: center;
}

.block_table .block_table__row span.item_title {
    display: flex;
    flex-direction: column;
}

@media (max-width: 740px) {
    .block_table .block_table__row span.item_title {
        min-width: 120px;
    }
    .item__title--row {
        flex-direction: column;
    }
}
