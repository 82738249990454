:root {
    /* Fonts */

    --font-family: 'Roboto Condensed', sans-serif;

    /* Colors */

    --background-color: hsl(214, 18%, 98%);
    --primary-color: hsl(351, 47%, 32%);
    --primary-color-light: hsl(351, 47%, 72%);
    --primary-color-very-light: hsl(351, 47%, 92%);
    --color-error: red;
    --black: hsl(309, 4%, 32%);
    --black-light: hsl(309, 4%, 72%);
    --blue: hsl(191, 23%, 94%);
    --blue-medium: hsl(191, 23%, 64%);
    --blue-extra-light: hsl(191, 23%, 97%);
    --green: hsl(143, 83%, 42%);
    --green-light: hsl(143, 83%, 72%);
    --green-very-light: hsl(143, 83%, 92%);

    --border-radius: 4px;
}

::selection {
    color: white;
    background-color: var(--primary-color);
}

body {
    font-family: var(--font-family);
    background-color: var(--background-color);
}

.layout {
    /* position: relative; */
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 4rem;
    height: calc(100vw - 4rem);
}

.main {
    /* position: relative; */
    margin: 5rem auto;
    max-width: 1200px;

    margin: 0 auto;
    flex: 1;
    padding-bottom: 8px;
    /* min-height: 500px; */
}

.columns {
    margin: 0.5em 0.5em 1.5em;
    display: flex;
}

.column__left {
    width: 400px;
}

.column__right {
    width: 100%;
    margin-left: 20px;
}

@media (max-width: 767px) {
    .sidebar,
    .sidebar-open {
        display: none;
        position: absolute;
        left: 0;
        padding: 0;

        visibility: hidden;
        width: 100%;
        height: 0vh;
        background-color: #fff;
        opacity: 0;
        top: 0em;
        transition: all 0.3s ease-out;
        list-style: none;
        font-weight: lighter;
    }

    .sidebar-open {
        display: flex;
        visibility: visible;
        top: 4rem;
        opacity: 1;
        height: calc(100vh - 4rem);
        position: fixed;
        transition: all 0.3s ease-in;
        z-index: 99;
        justify-content: center;
    }
}

@media (min-width: 768px) and (max-width: 1280px) {
    .main {
        margin-left: 5vw;
    }
    .sidebar-open {
        z-index: 9;
    }
}

@media (min-width: 768px) {
    .sidebar {
        height: calc(100vh - 4rem);
        position: fixed;
        height: 100%;
        overflow: auto;
    }

    .sidebar,
    .sidebar-open {
        display: flex;
        width: 5vw;
        max-width: 50px;
        background-color: white;
        padding: 0px;
        border-radius: var(--border-radius);
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    .sidebar-open {
        width: 20vw;
        max-width: 310px;
    }
}
