.fade__show {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 900;
    background-color: #000;
    opacity: 0;
    animation: opacity 0.25s linear;
    animation-fill-mode: forwards;
}

@keyframes opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}

.parent__modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    z-index: 999;
}

.modal {
    position: relative;
    margin: 5rem auto;
    padding: 0;
    background-color: #fff;
    border-radius: var(--border-radius);
    -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    width: 850px;
    z-index: 901;
}

.modal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--blue);
    padding: 15px 30px;
    background-color: #782b36;
    color: #fff;
    font-weight: bold;
    border-radius: var(--border-radius);

}

.modal__close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.5rem;
    cursor: pointer;
}

.modal__content {
    padding: 5px;
}

.modal__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 30px;
    margin: 25px 0;
    line-height: 1.5;
    color: var(--black);
    border-top: 1px solid #f2f2ff;
    background-color: #fcfcff;
    margin-bottom: 0;
    padding: 20px 30px;
}

.modal__map {
    width: 100%;
    height: 50vh;
    position: relative;
    overflow: hidden;
}

/** Responsive in workorders **/
@media (max-width: 769px) {
    .modal {
        width: calc(100% - 10px);
        margin: 5px;
    }

    .modal__footer {
        flex-direction: column;
    }
}
