body {
    margin: 0;
    padding: 0;
}
.spinner-section {
    position: fixed;
    justify-content: center;
    display: flex;
    align-items: center;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
}
.sk-double-bounce {
    width: 4em;
    height: 4em;
    position: relative;
    margin: auto;
}
.sk-double-bounce .sk-child {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(138, 0, 0, 0.685);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-double-bounce 2s infinite ease-in-out;
}

.sk-double-bounce .sk-double-bounce-2 {
    animation-delay: -1s;
}
@keyframes sk-double-bounce {
    0%,
    100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}
