.admin__head {
    margin: 0.5em 0.5em 1.5em;
    /* width: 100%; */
    max-width: calc(1200px - 1rem);
}

.admin__head--fixed {
    margin-bottom: 0;
    margin-top: 0.5rem;
}
.wrapper__admin__header,
.wrapper__admin__header--fixed {
    width: 100%;
    left: 0;
    top: 3.5rem;
    background-color: hsl(214, 18%, 98%);
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    opacity: 1;
    transition: 0.3s;
}
.wrapper__admin__header--fixed {
    position: fixed;
    transition: 0.3s;
}

.wrapper__admin__header--hidden {
    opacity: 0;

    top: -8rem;
}

.content--padding--fixed {
    padding-top: 4rem;
}

.admin__profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background-color: #fff;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.data__title {
    margin: 0 0 5px;
    font-size: 17px;
    /* text-transform: capitalize; */
}

.data__name {
    color: var(--primary-color);
    text-decoration: none;
}

.profile__actions {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
}

.action__link {
    text-decoration: none;
    color: var(--blue-dark);
    transition: color 0.3s ease-in-out;
    margin-left: 15px;
}

.action__link:hover {
    color: var(--primary-color);
}

.action__link--active {
    text-decoration: none;
    height: 40px;
    line-height: 40px;
    color: #fff;
    background-color: var(--primary-color);
    border-radius: var(--border-radius);
    padding: 0 14px;
    transition: all 0.3s ease-in-out;
    margin-left: 15px;
}

.action__link--active:hover {
    background-color: var(--primary-color-light);
}

.admin__body,
.admin__body--card,
.admin__body--clickable {
    margin: 0.5em;
}

.admin__body--fixed {
    position: fixed;
    z-index: 9;
    width: 100%;
    max-width: calc(1200px - 1rem);
}

.admin__body--clickable {
    margin: 0.5em;
    position: relative;
}

.admin__body--clickable:hover {
    cursor: pointer;
    color: var(--background-color-light);
}

.card__container,
.card__container--horizontal {
    display: flex;
    flex-direction: column;
}

.card__container--horizontal {
    flex-direction: row;
}

.admin__panel {
    margin-bottom: 25px;
    padding: 0;
    background-color: #fff;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.panel__title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--blue);
    padding: 25px 30px;
    align-items: center;
    background: var(--primary-color);
    color: var(--background-color);
}

.card__container--horizontal .panel__title,
.card__container .panel__title {
    padding: 10px 30px;
}

.panel__body {
    padding: 5px 0 0;
}

.panel__buttons {
    display: flex;
    flex-direction: row;
}

.button__schema,
.button__schema--medium,
.button__schema--medium-active,
.button__schema--big,
.button__schema--big-active {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blue);
    padding: 10px;
    border-radius: var(--border-radius);
    width: 100px;
    cursor: pointer;
    margin-left: 10px;
    height: 50px;
}

.button__schema--medium,
.button__schema--medium-active {
    justify-content: space-around;
    width: 110px;
}

.button__schema--medium-active,
.button__schema--big-active {
    background-color: var(--primary-color);
    color: white;
}

.button__schema--big,
.button__schema--big-active {
    width: 130px;
    margin-bottom: 5px;
    height: 75px;
}

.schema__towColumns {
    display: grid;
    grid-template-columns: 1.5fr 0.5fr;
}

.schema__legend {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.file__none {
    display: none;
}

.panel__grid {
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
}

.filter,
.filter--active {
    border: 0;
    background: transparent;
    color: var(--black);
    outline: none;
    font-size: 1rem;
}

.filter--active {
    font-weight: 700;
    color: var(--primary-color);
}

.panel__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
    /* margin: 25px 0; */
    line-height: 1.5;
    color: var(--black);
    border-top: 1px solid #f2f2ff;
    background-color: #fcfcff;
    margin-bottom: 0;
}

.panel__header {
    background-color: var(--blue);
    padding: 10px;
}

.panel__line {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.panel__inner {
    padding: 10px 30px;
    /* padding: 25px; */
}

.card__container--horizontal .panel__inner,
.card__container .panel__inner {
    padding: 10px;
}

.status__title {
    color: var(--green);
    font-size: 1.5rem;
    font-weight: 600;
}

.actions__title {
    color: var(--black-light);
    font-size: 1.4rem;
    font-weight: 600;
}

.panel__subheader {
    padding: 5px;
    background-color: var(--blue);
}

.sidebar {
    padding: 15px;
}

.sidebar__li {
    padding: 5px 10px;
}

.sidebar__li:hover,
.sidebar__li--active {
    background-color: var(--blue);
    cursor: pointer;
}

.data__badget {
    color: #fff;
    background-color: var(--green);
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}

.data__filter {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}
.link__badget,
.link__badget--active,
.link__assignation,
.link__assignation--active,
.link__SignatureWorkOrder,
.link__SignatureWorkOrder--active {
    color: #fff;
    background-color: var(--black);
    padding: 0.25em 0.4em;
    font-size: 1rem;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.5rem;
    cursor: pointer;
    width: 150px;
}

.link__edit--openModal {
    display: flex;
    max-width: 650px;
    width: auto;
    cursor: pointer;
    padding: 0.25em 0.4em;
    justify-content: self-end;
}
.link__edit--openModal:hover {
    font-size: 1rem;
    background-color: var(--black);
    color: #fff;
}

.link__edit--openModalItems {
    margin: 8px;
}

.link__badget:hover,
.link__SignatureWorkOrder:hover {
    background-color: var(--black-light);
}

.link__badget--active,
.link__SignatureWorkOrder--active {
    background-color: var(--green);
}

.link__assignation--active {
    background-color: var(--primary-color);
}

.link__SignatureWorkOrder,
.link__SignatureWorkOrder--active,
.link__assignation,
.link__assignation--active {
    width: 100%;
    max-width: 300px;
    padding: 8px;
    margin: 6px;
}

.ul__signature {
    display: flex;
    width: 630px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.title__signature {
    font-weight: bolder;
    border-bottom: 1px solid #ccc;
    padding-bottom: 8px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-around;
}

.space {
    height: 50px;
    margin: 6px 0px;
}

.ul__signature {
    display: flex;
    width: 630px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

@media (min-width: 768px) {
    .admin__body--card {
        flex-grow: 1;
        /* max-width: 650px; */
    }

    /* .card__container--horizontal {
        display: flex;
        flex-direction: row;
    } */
}

.alert__div{
    position: relative;
    padding: 1rem 1rem;
    border: 1px solid transparent;
    margin-bottom: 1.3rem;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: .25rem;
}
.warning{
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
}
