.sortable__container {
    margin: 40px;
}

.sortable__header,
.sortable__row {
    display: flex;
    flex-direction: row;
}

.sortable__header {
    height: 42px;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.sortable__header .header__tab,
.sortable__row .row__column {
    max-width: 500px;
    width: 100%;
}

.sortable__row .row__column {
    display: flex;
    align-items: center;
}

.sortable__header .header__tab--first {
    margin-left: 40px;
}

.row__column_handler {
    width: 40px;
}

@media (max-width: 500px) {
    .sortable__header .header__tab,
    .sortable__row .row__column {
        max-width: 300px;
        width: 100%;
    }
    .sortable__container {
        margin: 20px;
    }
}
