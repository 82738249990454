.form__body {
    list-style: none;
    padding-bottom: 1px;
}

.form__line,
.form__line--row,
.form__line--row-start,
.form__line--group,
.form__line--groupxL,
.form__line--start,
.form__line--striped,
.form__line--column {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    margin: 25px 0;
    line-height: 1.5;
    color: var(--black);
}
.form__line--column {
    flex-direction: column;
    align-items: flex-start;
}
.form__line--row {
    flex-direction: row;
}
.form__line--row-start {
    justify-content: flex-start;
}
.form__line--column .form__line_body {
    display: flex;
    flex-direction: row;
}

.form__line--start {
    align-items: flex-start;
}

.form__line--group,
.form__line--groupxL {
    background: var(--blue);
    height: 70px;
    margin: 0;
}

.form__line--groupxL {
    height: auto;
    max-height: 150px;
}

.form__line--grid {
    padding: 0 10px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 15px;
    margin-top: 15px;
}

.form__line--gridTable--PeopleAuthorized {
    padding: 0 10px;
    display: grid;
    flex-direction: column;
    align-items: flex-start;

    grid-template-columns: repeat(2, 1fr);
    /* grid-template-rows: repeat(2, 1fr); */
    grid-gap: 15px;
    margin-top: 15px;
}

.form__line--striped {
    background-color: hsl(214, 18%, 95%);
    padding: 25px 30px;
    margin: 0;
}

.form__line--striped--column {
    flex-direction: column;
}

.form__group,
.form__group--row,
.form__group--mini,
.form__group--mini--row,
.form__group--noMargin {
    position: relative;
    margin: unset; /* 2em 0; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 0 15px;
}

.form__group--noMargin {
    margin: 0px;
}

.form__group--mini,
.form__group--mini--row {
    width: 150px;
}
.form__group--row,
.form__group--mini--row {
    flex-direction: row;
    justify-content: flex-start;
}

.label__input,
.label__input--click {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
}

.label__input--click {
    text-align: center;
}

.label__input--click:hover {
    color: var(--primary-color);
    cursor: pointer;
}

.form__input,
.form__input--readonly,
.form__input_radio_button,
.form__input_radio_button--readonly {
    color: var(--black);
    width: 100%;
    box-sizing: border-box;
    border: 0;
    padding: 0.5em 0;
    border-bottom: 1px solid #ccc;
}

.form__input_radio_button {
    margin-right: 16px;
    width: auto;
}

.form__input:focus,
.form__input_radio_button:focus,
.form__input--readonly:focus {
    outline: none;
}
.form__input_radio_button--readonly,
.form__input--readonly {
    background-color: #efefef;
}

.form__input ~ .focus-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--primary-color);
    transition: 0.4s;
}

.form__input:focus ~ .focus-border,
.has-content.form__input ~ .focus-border {
    width: 100%;
    transition: 0.4s;
}

.form__error {
    color: var(--color-error);
}

.form__success {
    color: var(--color-green);
}

.form__line--footer,
.form__line--footer-schema {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 30px;
    margin: 25px 0;
    line-height: 1.5;
    color: var(--black);
    border-top: 1px solid #f2f2ff;
    background-color: #fcfcff;
    margin-bottom: 0;
}

.form__line--footer-schema {
    justify-content: space-between;
}

.form__line--subheader,
.form__line--subheader-red,
.form__line--subheader-green {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--blue);
    border-bottom: 1px solid var(--blue);
    background-color: antiquewhite;
    padding: 25px 30px;
}

.form__line--subheader-green {
    background-color: var(--green-very-light);
}

.form__line--subheader-red {
    background-color: var(--primary-color-very-light);
}

.button__submit,
.button__cancel,
.button__mini,
.button__mini--clear,
.button__disabled,
.button__success {
    color: #fff;
    background: var(--primary-color);
    white-space: nowrap;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    text-decoration: none;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    cursor: pointer;
    border-style: unset;
    outline: none;
    margin-left: 15px;
}

.button__submit:hover,
.button__cancel:hover,
.button__success:hover,
.button__mini:hover {
    background-color: var(--primary-color-light);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.button__cancel:hover {
    background-color: var(--black-light);
}

.button__cancel {
    background: var(--black);
}

.button__success:hover {
    background-color: var(--green-light);
}

.button__success {
    background: var(--green);
}

.button__disabled {
    background: #ccc;
}

.button__mini,
.button__mini--clear {
    height: 30px;
    line-height: 30px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.button__mini--clear {
    margin-left: 0px;
}

.button__action {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    background-color: white;
    white-space: nowrap;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    padding: 0 14px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    cursor: pointer;
    outline: none;
    margin-right: 15px;
}

.button__delete--top {
    position: absolute;
    right: -10px;
    top: -20px;
    color: var(--red);
    font-size: 1.5em;
    z-index: 10;
    padding: 4;
}

.button__delete--top:hover {
    cursor: pointer;
    color: var(--red-light);
    font-size: 1.8em;
}

.line__item,
.line__item--activated,
.line__item--transparent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: var(--blue);
    padding: 10px;
    border-radius: var(--border-radius);
    width: 140px;
    cursor: pointer;
    margin: 0 auto;
}

.line__item--activated {
    background-color: var(--primary-color);
    color: white;
}

.line__item--transparent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: var(--border-radius);
    width: 100%;
    cursor: pointer;
}

.line__item--transparent div {
    text-align: center;
}

.line__item__composes_images--transparent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border-radius: var(--border-radius);
    width: 100%;
    cursor: pointer;
    margin: 0 auto;
}

ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}
.content__timeline {
    margin: 25px;
    padding: 0;
    position: relative;
}

.checkbox__container,
.checkbox__container--background_white {
    display: block;
    position: relative;
    padding: 0px 18px;
    /* padding-left: 35px; */
    margin-bottom: 24px;
    margin-left: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox__container input[type='checkbox'],
.checkbox__container--background_white input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox__checkmarck {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

.checkbox__container--background_white .checkbox__checkmarck {
    background-color: white;
    border: solid black;
    border-width: 1px;
}

/* On mouse-over, add a grey background color */
.checkbox__container:hover input ~ .checkbox__checkmarck,
.checkbox__container--background_white:hover input ~ .checkbox__checkmarck {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox__container input:checked ~ .checkbox__checkmarck,
.checkbox__container--background_white input:checked ~ .checkbox__checkmarck {
    background-color: var(--primary-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox__checkmarck:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox__container input:checked ~ .checkbox__checkmarck:after,
.checkbox__container--background_white input:checked ~ .checkbox__checkmarck:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox__container .checkbox__checkmarck:after,
.checkbox__container--background_white .checkbox__checkmarck:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.panel__add {
    width: 100%;
    text-align: center;
    position: relative;
    margin-top: 16px;
}

.fa_plus--add,
.fa_plus--add-mini {
    padding: 5px;

    position: relative;

    font-size: 2.1em;
    color: var(--primary-color);
    cursor: pointer;
}

.fa_plus--add-mini i,
.fa_plus--add i {
    background-color: #fff;
}

.fa_plus--add-mini {
    margin-top: 5px;
    font-size: 1.65em;
}

.panel_add__button:hover {
    transform: scale(1.07);
}

.item__form--mini {
    max-width: 30px;
}

.annul_selections__container {
    margin-top: 16px;
    text-align: right;
}

.div__signature {
    display: flex;
    flex-direction: column;
}

/** Responsive in workorders **/
@media (max-width: 533px) {
    .form__line,
    .form__line--group,
    .form__line--groupxL,
    .form__line--start,
    .form__line--striped,
    .form__line--column,
    .profile__alert,
    .admin__profile {
        flex-direction: column;
    }
    .form__line--row {
        margin: 15px 0px;
    }

    .form__line--column .form__line_body {
        flex-direction: column;
    }

    .form__group,
    .form__group--row,
    .form__group--mini,
    .form__group--mini--row {
        margin: 5px 15px;
    }

    .form__group--mini--row {
        flex-direction: column;
    }
    .form__group--row {
        flex-direction: column;
    }

    .form__line--groupxL {
        max-height: none;
    }

    .profile__alert {
        display: flex;
        width: 100%;
    }

    .link__SignatureWorkOrder,
    .link__SignatureWorkOrder--active,
    .link__assignation,
    .link__assignation--active {
        max-width: 250px;
        margin: 6px 4px;
    }

    .div__signature,
    .ul__signature {
        width: 100%;
        background-color: var(--blue-extra-light);
        margin-bottom: 10px;
        padding-bottom: 10px;
    }

    .div__signature {
        margin-top: 22px;
    }

    .form__line--footer {
        flex-direction: column;
    }

    .button__submit,
    .button__cancel {
        width: 100%;
        margin: 5px 0;
        text-align: center;
    }

    .table__workOrderItems .-headerGroups {
        display: none;
    }

    .button__mini {
        height: 40px;
        line-height: 40px;
        padding: 0 14px;
        margin-top: 25px;
    }

    .title__signature {
        display: flex;
        flex-direction: column;
    }

    .title__signature--main {
        text-align: center;
        font-weight: bolder;
    }
    .title__signature--normal {
        text-align: right;
        padding-top: 12px;
        font-weight: normal;
        padding: 12px 8px;
    }

    .annul_selections__container {
        text-align: center;
    }
}

.buttons__panel--column {
    display: flex;
    flex-direction: row;
}

.button__mini:disabled{
    height: 30px;
    line-height: 30px;
    margin-top: 8px;
    margin-bottom: 8px;
    background: #ccc;
}

.button__mini:disabled:hover{
    cursor: initial;
    -webkit-transform: translateY(-0px);
    transform: translateY(-0px);
    -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
