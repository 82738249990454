.actions__tab,
.actions__tab--disabled,
.actions__tab--active {
    background-color: transparent;
    color: var(--black-light);
    border: none;
    font-size: 0.84rem;
    text-transform: uppercase;
    outline: none;
    padding: 0 0.7em;
    cursor: pointer;
}

.actions__tab--active {
    color: var(--primary-color);
    font-weight: 700;
    border-bottom: 5px var(--primary-color) solid;
    height: 50px;
}
.actions__tab--disabled {
    text-decoration: line-through;
}

.tab__circle,
.tab__circle--active {
    position: relative;
    margin-right: 5px;
    background-color: transparent;
    color: var(--black-light);
    border: 1px solid var(--black-light);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    box-shadow: unset;
}

.tab__circle--active {
    background-color: var(--primary-color);
    color: white;
    border: none;
    box-shadow: 0px 0px 15px 3px hsla(351, 47%, 32%, 0.3);
}

.span__line--through {
    text-decoration: line-through;
    font-weight: bold;
    color: red;
}
@media (max-width: 500px) {
    .actions__tab,
    .actions__tab--disabled,
    .actions__tab--active {
        max-width: 125px;
    }
}
