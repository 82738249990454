.alert,
.alert__success,
.alert__failure {
    max-width: 400px;
    width: 100%;
    border-radius: var(--border-radius);
    box-shadow: 0.0625rem 0.0625rem 0.0925rem #2d794b;
    padding: 20px;
    margin: 10px auto;
    position: fixed;
    z-index: 9;
    bottom: 15px;
    left: 15px;
}

.alert__success,
.alert__failure {
    z-index: 12px;
}

.alert__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
    border-radius: 50px;
    width: 18px;
    height: 18px;
    line-height: 15px;
    text-align: center;
}

.alert__title {
    color: #fff;
}

.alert__message {
    font-weight: 400;
    font-size: 14px;
}
.alert__failure {
    background: red;
}
.alert__failure .alert__close {
    border: 1px solid orange;
    color: white;
}
.alert__success {
    background: #5ed490;
}
.alert__success .alert__close {
    border: 1px solid #2d794b;
    color: #2d794b;
}

.fadeinup {
    animation-name: fadeInUp;
    animation-duration: 0.5s;
    opacity: 0;
    animation-fill-mode: forwards;
    text-decoration: none;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 50px, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 10px, 0);
    }
}

/** Responsive in workorders **/
@media (max-width: 480px) {
    .alert,
    .alert__success,
    .alert__failure {
        left: 0;
        width: 80%;
        margin: 10px 15px;
    }
}
