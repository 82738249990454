.card {
    width: 215px;
    height: 290px;
    background-color: white;
    box-shadow: 0px 0px 5px 1px hsla(309, 4%, 32%, 0.3);
    padding: 10px;
    border-radius: var(--border-radius);
    margin: 0 auto;
}

.card__img {
    height: 130px;
    width: auto;
    margin: 0 auto 10px;
    display: block;
}

.card__reference {
    text-align: center;
    color: #a1a1a1;
    font-size: 0.75rem;
}

.card__name {
    text-align: center;
    margin: 5px 0;
    font-weight: 700;
    height: 40px;
}

.card__price {
    text-align: center;
    margin: 10px;
    font-weight: 700;
}

.card__action {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.input__number {
    position: relative;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                 supported by Chrome and Opera */
}

.input__number input::-webkit-inner-spin-button,
.input__number input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.spin__minus,
.spin__plus {
    width: 32px;
    height: 32px;
    background-color: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.input__container {
    width: 32px;
    height: 32px;
}

.input__container input {
    width: inherit;
    height: inherit;
    text-align: center;
    border: 0;
    background-color: #efefef;
}

.card__button {
    border-radius: 4px;
    border: 0;
    width: 80px;
    background-color: var(--primary-color);
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.line-horizontal--center-vertical {
    height: 1px;
    color: gray;
    position: absolute;
    top: 48%;
    left: 0;
    border-bottom: 1px solid;
    width: 100%;
    display: inline-box;
    z-index: 1;
}
