.table {
    border: none;
    border-radius: var(--border-radius);
    /* width: 85%; */
}

.table .rt-thead.-header {
    box-shadow: none;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: unset;
    letter-spacing: 2px;
    background-color: var(--primary-color);
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
}

.table .rt-thead.-header .rt-tr {
    background: transparent;
    color: white;
    border-left: none;
}

.table .rt-tr {
    background-color: #efefef;
    color: var(--grey-dark);
    border-left: none;
    /* margin: 5px; */
    padding: 2px 0;
    /* text-align: center; */
    display: flex;
    align-items: center;
    /* padding-left: 12px; */
}

.table .rt-th {
    text-align: left;
    padding-left: 12px;
}

.cell_center {
    text-align: center;
}

.table .rt-tr:hover {
    background-color: var(--grey-very-light);
}

.table .rt-thead .rt-th.-cursor-pointer {
    outline: none;
}

.table .rt-thead .rt-th.-sort-asc {
    box-shadow: inset 0 3px 0 0 rgba(255, 255, 255, 0.8) !important;
}

.table .rt-thead .rt-th.-sort-desc {
    box-shadow: inset 0 -3px 0 0 rgba(255, 255, 255, 0.8) !important;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
    white-space: unset;
}

.table__subcomponent--row {
    padding: 10px;
}

.table__subcomponent--row > label {
    font-weight: bold;
}

.ReactTable.-striped.-highlight {
    width: calc(90vw);
    max-width: 1180px;
}

@media (max-width: 768px) {
    .ReactTable.-striped.-highlight {
        width: 92vw;
    }
}

@media (max-width: 410px) {
    .ReactTable.-striped.-highlight {
        width: 90vw;
    }
    .rt-noData {
        top: 35%;
    }
    .ReactTable .-pagination {
        flex-direction: column;
    }
}
